@import "util.scss";
.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}

.headingContainer {
  position: relative;
}

.heading {
  font-size: min(42vw, 750px);
  line-height: 1;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 700;
}

.orb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.paragraph {
  max-width: 580px;
  font-size: 16px;
  margin: 30px auto;
  text-align: center;
}

.buttonContainer {
  position: relative;
  z-index: 9;
  margin: 40px auto;
}