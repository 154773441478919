/**
 * Hover Mixin for applying :hover pseudo styles
 *
 * Add your own implementation for showing hovers
 * Example using Modernizr touch check: html.no-touch &:hover { @content; }
 * Leaving it to just :hover by default. But best practice to always use the mixin
 *
 * @param $extraSelector:string (optional) = an extra selector that can have the same styles as a hover (for mobile/active state)
 */
@mixin hover($extraSelector: null) {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }

  @if $extraSelector {
    #{$extraSelector} {
      @content;
    }
  }
}
