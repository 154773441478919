.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: remify(16px);
    line-height: 1.54; // better-alignment with icons
    color: #000;
    -webkit-text-fill-color: #000;
    padding: 6px 24px;
    border: 1px solid #fff;
    background-color: #fff;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 200ms ease-out, color 200ms ease-out,  -webkit-text-fill-color 200ms ease-out;

    @include respond-to(MIN_480) {
        padding: 6px 64px;
    }

    svg {
        path {
            transition: stroke .2s ease-out, fill .2s ease-out;
            fill: #000;
        }
    }

    &:after {
        content: '';
        transition: outline 100ms ease-out;
    }

    @include hover() {
        background-color: transparent;
        -webkit-text-fill-color: #fff;
        color: #fff;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    &:focus {
        &:after {
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            outline: 1.5px solid #fff;
        }
    }

    &:active {
        background-color: var(--volor-neutral-20);
        color: #fff;
        border-color: var(--volor-neutral-20);

        &:after {
            display: none;
        }

        svg {
            path {
                fill: #fff;
            }
        }
    }

    &disabled,
    &[disabled] {
        background-color: var(--volor-neutral-10);
        color: var(--volor-neutral-30);
        border-color: var(--volor-neutral-10);
        cursor: not-allowed;

        @include hover() {
            background-color: var(--volor-neutral-10);
            color: var(--volor-neutral-30);

            svg {
                path {
                    fill: var(--volor-neutral-30);
                }
            }
        }

        svg {
            path {
                fill: var(--volor-neutral-30);
            }
        }
    }
}

.button--icon-left {
    svg {
        margin-right: 8px;
    }
}

.button--icon-right {
    svg {
        margin-left: 8px;
    }
}

.button--loading {
    svg {
        transform-origin: center center;
        animation: rotating 1s linear infinite;
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.button--outline {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //font-family: $porsche-bold;
    font-weight: 600;
    font-size: remify(16px);
    line-height: var(--line-height);
    color: #fff;
    padding: 8px 24px;
    border: 1px solid #fff;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 200ms ease-out, color 200ms ease-out;

    @include respond-to(MIN_480) {
        padding: 8px 64px;
    }

    svg {
        path {
            fill: #fff;
        }
    }

    &:after {
        content: '';
        transition: outline 100ms ease-out;
    }

    @include hover() {
        background-color: #fff;
        color: #000;

        svg {
            path {
                fill: #000;
            }
        }
    }

    &:focus {
        &:after {
            position: absolute;
            top: -4px;
            left: -4px;
            height: calc(100% + 8px);
            width: calc(100% + 8px);
            outline: 1.5px solid #fff;
        }
    }

    &:active {
        color: var(--volor-neutral-40);
        border-color: var(--volor-neutral-30);
        background-color: transparent;

        &:after {
            display: none;
        }

        svg {
            path {
                fill: var(--volor-neutral-40);
            }
        }
    }

    &disabled,
    &[disabled] {
        color: var(--volor-neutral-20);
        border-color: var(--volor-neutral-20);
        cursor: not-allowed;

        @include hover() {
            background-color: #000;
            color: var(--volor-neutral-20);
            border-color: var(--volor-neutral-20);

            .--path-stroke-white {
                path {
                    stroke: var(--volor-neutral-20);
                }
            }
        }

        .--path-stroke-white {
            path {
                stroke: var(--volor-neutral-20);
            }
        }
    }
}

.button {
    &[data-color-theme="dark"] {
        color: #fff;
        border: 1px solid #000;
        background-color: #000;

        svg {
            path {
                fill: #fff;
            }
        }

        @include hover() {
            background-color: transparent;
            color: #000;

            svg {
                path {
                    fill: #000;
                }
            }
        }

        &:focus {
            &:after {
                outline: 1.5px solid #000;
            }
        }

        &:active {
            background-color: var(--volor-neutral-50);
            color: #000;
            border-color: var(--volor-neutral-50);

            svg {
                path {
                    fill: #000;
                }
            }
        }
    }
}

.button--outline {
    &[data-color-theme="dark"] {
        color: #000;
        border: 1px solid #000;
        background-color: transparent;

        svg {
            path {
                fill: #000;
            }
        }

        @include hover() {
            background-color: #000;
            color: #fff;

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        &:focus {
            &:after {
                outline: 1.5px solid #000;
            }
        }

        &:active {
            color: var(--volor-neutral-60);
            border-color: var(--volor-neutral-50);
            background-color: transparent;

            svg {
                path {
                    fill: var(--volor-neutral-60);
                }
            }
        }
    }
}

.button__error-text {
    display: flex;
    color: var(--color-brand);
    font-size: 0.875em;
    margin-top: 10px;
}

.button__has-lottie-animation {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    padding: 8px 40px;

    .icon-holder {
        svg path {
            fill: #fff;
        }
    }
}

.button__lottie-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        display: block;
        position: absolute;
        top: -12px;
        left: -13px;
        width: auto !important;
        height: auto !important;
        margin-left: 0;
        transform: scale(1.2, 1.1) !important;
        transform-origin: left top;
    }
}
