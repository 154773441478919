@import "util.scss";
@import 'src/components/organisms/contact-form/_button.scss';

//.button {
//  padding-right: 48px;
//  padding-left: 48px;
//  min-height: 40px;
//  background-color: #FFFFFF;
//  color: #000000;
//  text-align: center;
//  font-size: 16px;
//  font-weight: 600;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  text-decoration: none;
//}

.buttonIcon {
  margin-left: 8px;
  width: 25px;
}